/*.geosuggest__suggests-wrapper{
  margin-top:0px !important;
}*/

a.boxclose{
    margin-top:-30px;
    cursor:pointer;
    color: #fff;
    font-size: 31px;
    font-weight: bold;
    display: inline-block;
    line-height: 0px;
}

.boxclose:before {
    font-size: 14px;
    position:relative;
    top:0px;
    left:-2px;
    content: "×";
}
a.topboxclose{
    /*margin-top:-30px;*/
    cursor:pointer;
    color: #fff;
    font-size: 31px;
    font-weight: bold;
    display: inline-block;
    line-height: 0px;
}

.topboxclose {
    /*font-size: 4rem;*/
    position:relative;
    top:0px;
    right:5px;
    width:20px;
    height:20px;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYWxxdWVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDM5MS4yIDM5MS4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzOTEuMiAzOTEuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxyZWN0IHg9Ii03Mi43IiB5PSIxODcuMSIgdHJhbnNmb3JtPSJtYXRyaXgoMC43MDcxIC0wLjcwNzEgMC43MDcxIDAuNzA3MSAtODAuOTU2NSAxOTUuNTE3MSkiIHdpZHRoPSI1MzYuNSIgaGVpZ2h0PSIxNi43Ii8+Cgk8cmVjdCB4PSIxODcuMiIgeT0iLTcyLjgiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzA3MSAtMC43MDcxIDAuNzA3MSAwLjcwNzEgLTgwLjk1NjUgMTk1LjUxNzEpIiB3aWR0aD0iMTYuNyIgaGVpZ2h0PSI1MzYuNSIvPgo8L2c+Cjwvc3ZnPgo=)
    /*content: "×";*/
}
.mini-cart {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);  
            animation: fadein 0.5s;
}

.mini-cart::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
