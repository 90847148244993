#container-circles {
  position: absolute;
  left: 50%;
  bottom: 90px;
}

.fa-save:hover {
  color: #333;
}

#outer-circle {
  position: absolute;
  left: -37px;
  height: 75px;
  width: 75px;
  /*
    opacity of 0.4
  */
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  z-index: 1;
}

#inner-circle {
  position: absolute;
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background: white;
  border-radius: 50%;
  /*
   Offset the position correctly with
   minus half of the width and minus half of the height
  */
  margin: -22px 0px 0px -22px;
  /*
    On the top of outer-circle
  */
  z-index: 2;
}

#inner-circle.is-clicked {
  height: 38px;
  width: 38px;
  margin: -19px 0px 0px -19px;
}

.polaroid-images {
  margin: 10px 0;
  float: left;
}

.polaroid-images span {
  background: white;
  display: inline;
  float: left;
  margin: 0 15px 30px;
  padding: 10px 10px 25px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  transition: all 0.15s linear;
  z-index: 0;
  position: relative;
}

.polaroid-images span:after {
  color: #333;
  font-size: 20px;
  content: attr(title);
  position: relative;
  top: 15px;
}

.polaroid-images img {
  display: block;
  width: inherit;
}

.polaroid-images span:nth-child(2n) {
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  transform: rotate(4deg);
}

.polaroid-images span:nth-child(3n) {
  -webkit-transform: rotate(-24deg);
  -moz-transform: rotate(-24deg);
  transform: rotate(-24deg);
}

.polaroid-images span:nth-child(4n) {
  -webkit-transform: rotate(14deg);
  -moz-transform: rotate(14deg);
  transform: rotate(14deg);
}

.polaroid-images span:nth-child(5n) {
  -webkit-transform: rotate(-18deg);
  -moz-transform: rotate(-18deg);
  transform: rotate(-18deg);
}

.polaroid-images span:hover {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.delete_photo {
  display: none;
}

.polaroid-images span:hover > .delete_photo {
  display: block !important;
  z-index: 10;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: 450px !important;
}

#display-error h1 {
  font-size: 16px !important;
}

.orders_table td {
  padding: 5px !important;
  margin-bottom: 10px;
}

.messagesBox {
  width: 200px;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  padding-left: 5px;
  position: relative;
  background-color: #006aff;
  border-radius: 5px;
}

.messagesBox span {
  font-weight: bold;
  color: #fff;
  position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  transition: 1s;
}

.messagesBox:hover span {
  transform: translateX(calc(200px - 100%));
  transition: 3s ease-in-out;
}

.instructionsBox {
  min-width: 190px;
  /* height: 25px; */
  /* overflow: hidden; */
  line-height: 20px;
  position: relative;
  background-color: yellow;
  border-radius: 5px;
  padding: 2px;
  margin: 0px;
  font-size: 10px;
  padding-left: 5px;
}

.instructionsBox span {
  color: red;
  /* position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  transition: 1s; */
}

.instructionsBox:hover span {
  /* transform: translateX(calc(170px - 100%));
  transition: 3s ease-in-out; */
}

.addressBox {
  width: 200px;
  height: 25px;
  overflow: hidden;
  line-height: 20px;
  position: relative;
  color: rgb(150, 150, 150);
  border-radius: 5px;
  padding: 2px;
  margin: 0px;
  font-size: 10px;
}

.addressBox span {
  /*color:#fff;*/
  position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  transition: 1s;
}

.addressBox:hover span {
  transform: translateX(calc(200px - 100%));
  transition: 3s ease-in-out;
}

.order-progress-container {
  minimumwidth: 300px;
  padding: 20px 0px 0;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.order-progress-list {
  margin: 0;
  margin-left: -13px;
  padding: 0;
  list-style-type: none;
}

.order-progress-item {
  position: relative;
  display: inline-block;
  width: 70px;
  text-align: center;
  line-height: 3em;
  font-size: 0.7em;
}

.order-progress-item:after {
  position: absolute;
  display: block;
  z-index: 0;
  top: -2px;
  left: -35px;
  height: 2px;
  width: 80px;
  content: "";
}

.order-progress-item:before {
  position: absolute;
  z-index: 1;
  top: -6px;
  left: 35px;
  height: 10px;
  width: 10px;
  border-radius: 1.2em;
  border: none;
  line-height: 1.2em;
  content: " ";
  background-color: #e60000;
}

.order-progress-item:first-child:after {
  display: none;
}

.order-progress-item.done {
  /*color: #A4A4A4;*/
}

.order-progress-item.todo {
  color: #dddadd;
}

.order-progress-item.todo:after {
  background: #f1f1f1;
}

.order-progress-item.todo:before {
  background-color: #dadada;
}

ol.progressbar {
  display: table;
  list-style-type: none;
  margin: 0 auto 20px auto;
  padding: 0;
  table-layout: fixed;
  width: 90%;
}

ol.progressbar li {
  font-size: 0.6em;
  display: table-cell;
  text-align: center;
  padding-bottom: 10px;
  white-space: nowrap;
  position: relative;
}

ol.progressbar li a {
  color: inherit;
}

ol.progressbar li {
  color: silver;
  border-bottom: 2px solid silver;
}

ol.progressbar li.done {
  color: black;
}

ol.progressbar li:before {
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -7.5px;
  color: white;
  height: 15px;
  width: 15px;
  line-height: 15px;
  border: 2px solid silver;
  border-radius: 15px;
}

ol.progressbar li.done:before {
  content: "\2713";
  color: white;
}

ol.progressbar li.todo:before {
  content: " ";
  background-color: white;
}

ol.progressbar li:first-child {
}

.val-err-tooltip {
  /*color: #ff0000 !important;*/
}
