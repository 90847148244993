
a{
  text-decoration: none;
}
span.fontawesome{
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  width:16px;
}
.scroll {
  opacity: 0.5;  
  border:#ccc solid 2px;
  z-index:5;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 95px;
  right: 30px;
  border-radius: 25px;
  border: none;
  z-index: 5;
}
.scroll:hover {
    opacity: 0.8;
}
.callMeButton, .fb_dialog_content{
  opacity: 0.4;
}
.callMeButton:hover, .fb_dialog_content:hover{
  opacity: 1;
}

.slider-wrapper > .nextButton:hover, .slider-wrapper > .previousButton:hover{
    transition: all 0.5s ease-in-out !important;
    transition: all 0.5s ease-in-out !important;
    -webkit-transition: all 0.5s ease-in-out !important; /** Chrome & Safari **/
    -moz-transition: all 0.5s ease-in-out !important; /** Firefox **/
    -o-transition: all 0.5s ease-in-out !important; /** Opera **/
}

.closeNotify{
  padding:6px;
  filter: invert(1);
  display: inline-block;
  float: right;
  width:20px;
  height:20px;
  font-size: 0;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYWxxdWVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDM5MS4yIDM5MS4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzOTEuMiAzOTEuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxyZWN0IHg9Ii03Mi43IiB5PSIxODcuMSIgdHJhbnNmb3JtPSJtYXRyaXgoMC43MDcxIC0wLjcwNzEgMC43MDcxIDAuNzA3MSAtODAuOTU2NSAxOTUuNTE3MSkiIHdpZHRoPSI1MzYuNSIgaGVpZ2h0PSIxNi43Ii8+Cgk8cmVjdCB4PSIxODcuMiIgeT0iLTcyLjgiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzA3MSAtMC43MDcxIDAuNzA3MSAwLjcwNzEgLTgwLjk1NjUgMTk1LjUxNzEpIiB3aWR0aD0iMTYuNyIgaGVpZ2h0PSI1MzYuNSIvPgo8L2c+Cjwvc3ZnPgo=)
    
}
.fb_dialog{
  z-index: 3 !important;
}

#page-wrap{
  min-height: 100%;
  height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto auto;
}

.Phone {
  position: relative;
  display: block;
  margin: 0;
  width: 60px;
  height: 60px;
  font-size: 25vmin;
  border-radius: 0.5em;
  box-shadow:
    0 0 0 0em rgba(#3498db, 0),
    0em 0.05em 0.1em rgba(#000000, 0.2);
  transform: translate3d(0, 0, 0) scale(1);
}

.Phone::before,
.Phone::after {
  position: absolute;
  content: "";
}

.Phone::before {
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(#fff, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(0);
}

.Phone::after {
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}

.Phone.is-animating {
  animation: phone-outer 3000ms infinite;
  
  &::before {
    animation: phone-inner 3000ms infinite;
  }
  
  &::after {
    animation: phone-icon 3000ms infinite;
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
      0 0 0 0em rgba(#3498db, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow:
      0 0 0 0em rgba(#3498db, 0.1),
      0em 0.05em 0.1em rgba(#000000, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
      0 0 0 0.5em rgba(#3498db, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
      0 0 0 0em rgba(#3498db, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}

@keyframes phone-icon {
  0% { transform: translate3d(0em, 0, 0); }
  2% { transform: translate3d(0.01em, 0, 0); }
  4% { transform: translate3d(-0.01em, 0, 0); }
  6% { transform: translate3d(0.01em, 0, 0); }
  8% { transform: translate3d(-0.01em, 0, 0); }
  10% { transform: translate3d(0.01em, 0, 0); }
  12% { transform: translate3d(-0.01em, 0, 0); }
  14% { transform: translate3d(0.01em, 0, 0); }
  16% { transform: translate3d(-0.01em, 0, 0); }
  18% { transform: translate3d(0.01em, 0, 0); }
  20% { transform: translate3d(-0.01em, 0, 0); }
  22% { transform: translate3d(0.01em, 0, 0); }
  24% { transform: translate3d(-0.01em, 0, 0); }
  26% { transform: translate3d(0.01em, 0, 0); }
  28% { transform: translate3d(-0.01em, 0, 0); }
  30% { transform: translate3d(0.01em, 0, 0); }
  32% { transform: translate3d(-0.01em, 0, 0); }
  34% { transform: translate3d(0.01em, 0, 0); }
  36% { transform: translate3d(-0.01em, 0, 0); }
  38% { transform: translate3d(0.01em, 0, 0); }
  40% { transform: translate3d(-0.01em, 0, 0); }
  42% { transform: translate3d(0.01em, 0, 0); }
  44% { transform: translate3d(-0.01em, 0, 0); }
  46% { transform: translate3d(0em, 0, 0); }
}