.react-tabs{
  width:99% !important;
}
.prod_label_grey{
  background: #EFEFEF;
  border-radius: 5px;
  margin-top:2px;
  margin-bottom:0px;
  margin-left:5px;
  margin-right:-5px;
}

.prod_label{
  border-radius: 5px;
  margin-top:2px;
  margin-bottom:0px;
}

.dropzone{
  width: 150px;
  height: 150px;
  border: 1px dashed #ccc;
  border-radius: 5px;
}
.dropzone p{
  margin: 30px;
  margin-top: 40%;
  text-align: center;
}

.product_details > .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #495057 !important;
    border-color: #dee2e6 #dee2e6 #fff;
}
section.product_details .row{
  margin-bottom: 2px !important;
}
textarea.product_details{
  border:#efefef 1px solid;
  border-radius: 5px;
  color:#666;
  padding: 5px;
  width: 100%;
  min-height: 200px;
  margin:5px;
  text-align: left;
}
select.product_details{
  border:#efefef 1px solid;
  border-radius: 5px;
  color:#666;
  padding: 5px;
  width: 100%;
  margin:5px;
  margin-top:7px;
  text-align: left;
}
input.product_details{
  border:#efefef 1px solid;
  border-radius: 5px;
  color:#666;
  padding: 5px;
  width: 100%;
  margin:5px;
  margin-top:7px;
  text-align: left;
}
input.product_details::placeholder {
  color:#ccc;
}
label.product_details{
  color:#000;
  text-align: left;
  padding: 5px;
  width: 100%;
  margin-top:10px;
}


.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #efefef;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #000;
  border-color: #efefef;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}