/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */

.gpsMarker:hover, .clearAddress:hover{
  color: #000 !important;
  transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.geosuggest {
  font-size: 18px;
  position: relative;
  width: 100%;
  float:left;
  padding: 9px 1% 0px 1%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__suggests-wrapper{
  margin-top:40px;
}

.geosuggest__input {
  /* 
  font-weight: 300;
  padding-left:20px;
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: .5em 30px .5em 30px;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  */
  transition: all 400ms ease;
  font-weight: 300; 
  font-size: 14px; 
  line-height: 24px; 
  width: 100%; 
  padding: 18px 2px; 
  background: rgb(255, 255, 255); 
  border-top: none; border-right: none; 
  border-bottom: 1px solid rgba(200, 200, 200,0.5); 
  border-left: none; border-image: initial; 
  font-family: ff-clan-web-pro, "Helvetica Neue", Helvetica, sans-serif; 
  outline: none; color: rgb(0, 0, 0); 
  height: 44px; 
  box-sizing: border-box; 
  border-radius: 0px; 
  margin-bottom: 10px;
}
.geosuggest__input:focus {
  border-color: #000;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border-radius:3px;
  /* border: 1px solid #ccc;*/
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}