.facebook-button-class{
  background: #4267b2 !important;
  color: white !important;
  margin-bottom: 1rem!important;
  font-family: 'Open Sans', sans-serif !important;
  width: 50%;
  height: 43px;
  font-size: 14px;
  font-weight: 100 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  margin-left: 8px;
  position: relative;
  top: 1px;
}
.facebook-button-class > .fa-facebook{
  margin-right:18px !important;
}

.ordersItemBox {
    position: relative;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: #efefef solid 1px;
    padding: 10px;
    margin: 5px;
    vertical-align: top;
    width:100%;
}
.ordersItemBox:hover {
  background-color: #efefef;
}

.google-login{  
  font-size: 11px !important;
  padding:5px !important;
  background-color: #DD4333;
  border:none;
  color:#fff;
  margin:2px;
  width:120px;
  position:relative;
  top:1px;
}
.kep-login-facebook{
  font-size: 11px !important;
  padding:5px !important;
  margin:2px;
  border: none !important;
  width:120px;
}
.accountModal .nav-link{
  font-size:14px;
  color:#999;
}
.accountBody .accountModal{
  background-color:#FFF;
}
.accountBody{
  background-color:#FFF;
}
.accountBody .nav-item, .accountBody .nav-tabs .active,  .accountBody .nav-tabs{
  border-color: #efefef !important;
}
.accountBody .tab-content{
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.step5 ~ div.footer-buttons, .step5 ~ div.signin {display: none}

.val-err-tooltip {
    padding: 3px 5px 3px 10px;
    font-size: 12px;
    margin-bottom: 10px;
    color: #ff0000;
  }
.modal-content {
  border-radius: 4px !important;
  border-color: #fff !important;
  padding:10px;
}
.modal-body{
  
}
.accountModal .modal-dialog {
  width: 600px !important;
  max-width:90%;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s;
}
.progress-nav {
  margin-left: 0;
  list-style: none;
  position:relative;
  top:-45px;
  left:0px;
}
.modal-header, .modal-footer{
  height:40px;
  border: none !important;
}
.modal-footer{
  border: none !important;
}
.modal-header .close{
  color:#000;
  border-color: #000;
  font-size: 1rem;
}

.progress-nav__item:after, .progress-nav__item--complete:after {
  content: "";
  width: 25px;
  height: 1px;
  position: absolute;
  top: 3px;
  left: 8px; }

.progress-nav__item {
  float: left;
  color: #C6C6C6;
  position: relative;
  border: 1px solid;
  height: 9px;
  width: 9px;
  border-radius: 4.5px;
  margin-right: 25px; }
  .progress-nav__item:after {
    background: #C6C6C6; }
  .progress-nav__item:last-child:after {
    background: transparent; }

.progress-nav__item-doing {
  background-color: transparent; 
}
.progress-nav__item-doing:after {
    background: #C6C6C6; 
}

