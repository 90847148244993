
.card {
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05), 0 0 0 1px rgba(63, 63, 68, .1);
    background-color: #fff;
    margin-bottom: 30px
}

.card .image {
    width: 100%;
    overflow: hidden;
    height: 320px;
    border-radius: 4px 4px 0 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.card .image img {
    width: 100%
}
.card-title{
  margin-top:5px;
  font-size:1rem;
}

.card .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, .68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0)
}

.card .filter .btn {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.card:hover .filter {
    opacity: 1;
    filter: alpha(opacity=100)
}

.card .btn-hover {
    opacity: 0;
    filter: alpha(opacity=0)
}

.card:hover .btn-hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

.card .content {
    padding: 15px 15px 10px
}

.card .header {
    padding: 15px 15px 0
}

.card .category,
.card label {
    font-size: 14px;
    font-weight: 400;
    color: #9a9a9a;
    margin-bottom: 0
}

.card .category i,
.card label i {
    font-size: 16px
}

.card label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase
}

.card .title {
    margin: 0;
    color: #333;
    font-weight: 300
}

.card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px
}

.card .description {
    font-size: 14px;
    color: #333
}

.card .footer {
    padding: 0;
    background-color: transparent;
    line-height: 30px
}

.card .footer .legend {
    padding: 5px 0
}

.card .footer hr {
    margin-top: 5px;
    margin-bottom: 5px
}

.card .stats {
    color: #a9a9a9
}

.card .footer div {
    display: inline-block
}

.card .author {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase
}

.card .author i {
    font-size: 14px
}

.card h6 {
    font-size: 12px;
    margin: 0
}

.card.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #ddd;
    content: "";
    position: absolute
}

.card .ct-chart {
    margin: 30px 0;
    height: 245px
}

.card .table tbody td:first-child,
.card .table thead th:first-child {
    padding-left: 15px
}

.card .table tbody td:last-child,
.card .table thead th:last-child {
    padding-right: 15px
}

.card .alert {
    border-radius: 4px;
    position: relative
}

.card .alert.alert-with-icon {
    padding-left: 65px
}

.card-user .image {
    height: 110px
}

.card-user .image-plain {
    height: 0;
    margin-top: 110px
}

.card-user .author {
    text-align: center;
    text-transform: none;
    margin-top: -70px
}

.card-user .avatar {
    width: 124px;
    height: 124px;
    border: 5px solid #fff;
    position: relative;
    margin-bottom: 15px
}

.card-user .avatar.border-gray {
    border-color: #eee
}

.card-user .title {
    line-height: 24px
}

.card-user .content {
    min-height: 240px
}

.card-price .footer,
.card-user .footer {
    padding: 5px 15px 10px
}

.card-price hr,
.card-user hr {
    margin: 5px 15px
}

.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0
}

.card-plain .image {
    border-radius: 4px
}

.card-stats .icon-big {
    font-size: 3em;
    min-height: 64px
}

.card-stats .icon-big i {
    font-weight: 700;
    line-height: 59px
}

.card-stats .numbers {
    font-size: 2em;
    text-align: right
}

.card-stats .numbers p {
    margin: 0
}

.card-user .avatar.border-gray {
    border-color: #eee;
}
.card-user .avatar {
    width: 124px;
    height: 124px;
    border: 5px solid #fff;
    position: relative;
    margin-bottom: 15px;
}
.card .avatar {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
}
img {
    vertical-align: middle;
}
.card-user .title {
    line-height: 24px;
}
.card .title {
    margin: 0;
    color: #333;
    font-weight: 300;
}
.h4, h4 {
    font-size: 22px;
    line-height: 30px;
}
.card-user .author {
    text-align: center;
    text-transform: none;
    margin-top: -70px;
}
.card .author {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}


.val-err-tooltip {
  /*color: #ff0000 !important;*/
}
.staff-invoice-POS{
    box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
    padding:2mm;
    margin: 0 auto;
    width: 98%;
    background: #FFF;
    display:inline-block;
    margin:5px;
}    
    
  ::selection {background: #f31544; color: #FFF;}
  ::moz-selection {background: #f31544; color: #FFF;}
  .staff-invoice-POS h1{
    font-size: 1.5em !important;
    color: #222;
  }
  .staff-invoice-POS h2{
    font-size: 1em !important;
    margin:10px;
  }
  div.box
  {
    width:300px;
    height:80px;
    display:inline-block;
    vertical-align:top;
    box-sizing:bstaff-box;
    padding:10px;
    color:#999;
    position:relative;
    top:-70px;
  }

  .staff-invoice-POS h3{
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
  }
  .staff-invoice-POS p{
    font-size: .7em !important;
    color: #666;
    line-height: 1.2em;
  }
   
  #mid,#bot{ /* Targets all id with 'col-' */
    bstaff-bottom: 1px solid #EEE;
  }

  #top{min-height: 100px;}
  #mid{min-height: 80px;} 
  #bot{ min-height: 50px;}

  #top .logo{
    width: 60px;
  }
  .clientlogo{
    float: left;
    height: 60px;
    width: 60px;
    background-size: 60px 60px;
    bstaff-radius: 50px;
  }
  .info{
    display: block;
    /*float:left;*/
    margin-left: 0;
  }/*
  .title{
    float: right;
  }
  .title p{text-align: right;} */
  table{
    width: 100%;
    bstaff-collapse: collapse;
  }
  td{
    /*padding: 5px 0 5px 15px;
    bstaff: 1px solid #EEE*/
  }
  .tabletitle{
    /*padding: 5px;*/
    font-size: .5em;
    background: #EEE;
  }
  .service{bstaff-bottom: 1px solid #EEE;}
  .item{width: 24mm;}
  .itemtext{font-size: .5em;}

  #legalcopy{
    margin-top: 5mm;
  } 


  /* Order Pending, Order Confirmed, Order Ready (staff prepared), Order in transit, Order complete*/
  div.box.staff_pending_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER PENDING - UNPAID";      
  }
  div.box.staff_pending_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left:15px;
      padding-right: 15px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER PENDING - PAID";      
  }
  div.box.staff_confirmed_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER CONFIRMED - UNPAID";      
  }
  div.box.staff_confirmed_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER CONFIRMED - PAID";      
  }
  div.box.staff_ready_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER READY - UNPAID";      
  }
  div.box.staff_ready_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER READY - PAID";      
  }
  div.box.staff_in_transit_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER IN TRANSIT - UNPAID";      
  }
  div.box.staff_in_transit_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER IN TRANSIT - PAID";      
  }
  div.box.staff_complete_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER COMPLETE - UNPAID";      
  }
  div.box.staff_complete_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER COMPLETE - PAID";      
  }
  div.box.staff_cancelled_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER CANCELLED - UNPAID";      
  }
  div.box.staff_cancelled_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER CANCELLED - PAID";      
  }
  div.box.staff_arrived_not_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER ARRIVED - UNPAID";      
  }
  div.box.staff_arrived_paid:after
  {
      position:absolute;
      top:70px;
      left:10px;
      padding:5px;
      padding-left: 5px;
      padding-right: 5px;
      z-index:1;
      font-family:Arial,sans-serif;
      -webkit-transform: rotate(-5deg); /* Safari */
      -moz-transform: rotate(-5deg); /* Firefox */
      -ms-transform: rotate(-5deg); /* IE */
      -o-transform: rotate(-5deg); /* Opera */
      transform: rotate(-5deg);
      font-size:1.1em;
      color:#c00;
      background:#fff;
      bstaff:solid 4px #c00;
      bstaff-radius:5px;
      zoom:1;
      filter:alpha(opacity=20);
      opacity:0.2;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: 0 0 2px #c00;
      content:"ORDER ARRIVED - PAID";      
  }